<template>
  <a-modal :visible="showModal" :width="dialogWidth" :destroyOnClose="true" @cancel="handleCancel" class="know-modal">
    
      <template v-slot:title>
        <span style="font-size:13px">{{ title }}</span>
      </template>
      <template v-slot:closeIcon>
        <a-icon type="close" style="color: #3C4A54;font-size: 12px;margin-top:14px;margin-right:14px;float:right;" />
      </template>
      <template v-slot:footer>
        <div>
          <a-button type="primary" v-show="!isView" style="" @click="saveKnowledge">保存</a-button>
          <a-button style="" @click="close">关闭</a-button>
        </div>
      </template>

      <div class="main">
        <a-spin :spinning="loading">
          <a-form-model ref="form" :model="form" :rules="rules" :labelCol="{span:4}" :wrapperCol="{span:20}" style="margin:5px; padding-bottom: 10px;">
              <a-row>
                  <a-col :span="8">
                      <a-form-model-item label="赋值状态：" prop="verified">
                          <div v-if="this.isView">
                            <a-input v-model="form.verified" disabled="disabled" readonly/>
                            </div>
                          <a-select v-model="form.verified" placeholder="赋值状态" v-else>
                              <a-select-option key="已赋值" value="已赋值">已赋值</a-select-option>
                              <a-select-option key="未赋值" value="未赋值">未赋值</a-select-option>
                          </a-select>
                      </a-form-model-item>
                  </a-col>
              </a-row>
          </a-form-model>
          <!--< div v-if="dialogType=='add'" style="padding-bottom: 10px;">
            <span style="margin: 0 10px">文件名称：</span>
            <a-select style="width:200px;" placeholder="" :maxTagCount="1" @change="handleChange">
            <a-select-option v-for="item in files" :key="item.name" :value="item.name">{{ item.name }}</a-select-option>
            </a-select>
            <a-button type="primary" icon="search" style="margin: 0 10px" @click="search">搜索</a-button>
          </div>
          <div v-else style="font-size: 2em;color:green;font-weight: bolder;text-align: center;">
            {{folderName}}
          </div>-->
          <div class="imgContainer" >
              <div class="upload">
                  <div class="upload_warp_left" @click="fileClick" style="margin-top: 4px;margin-left:10px;" v-if="!isView">
                      <a-button icon="ios-cloud-upload-outline" class="btn btn-success" type="primary" size="small">上传图片</a-button>
                  </div>
                  <!--<div class="upload_warp_text" v-if="!isView">
                      选中{{imageFiles.length}}张文件，共{{bytesToSize(this.size)}}
                  </div>-->
                  <div class="upload_warp" style="border: 1px solid white;">
                      <div class="upload_warp_img" v-show="imageFiles.length!=0" >
                          <div class="upload_warp_img_div" v-for="(item,index) of imageFiles" :key="index">
                              <div class="upload_warp_img_div_top" >
                                  <!-- <div class="upload_warp_img_div_text" :title="item.name">
                                      {{item.name}}
                                  </div> -->
                                  <a-button icon="delete" class="upload_warp_img_div_del" style="background-color:transparent" @click="onDeleteImg(item)" v-if="!isView"></a-button>
                              </div>
                              <preview-img :src="item.src"/>
                              <!-- <img :src="item.src"> -->
                          </div>
                      </div>
                  </div>
                  <input @change="fileChange($event)" type="file" id="upload_file" multiple style="display: none"/>
              </div>
          </div>
          <div>
            <div class="know-add-btn" v-if="!isView">
              <a-button class="yellow" type="primary" @click="handleAddRow">增加行</a-button>
              <!-- <a-button class="yellow" type="primary" @click="handleDeleteRow">删除行</a-button> -->
            </div>
             <!-- :row-selection="isView ? undefined : rowSelection" -->
            <a-table class="dialog-table" rowKey="key" :columns="featureCols" :dataSource="featureDatas" ref="eleFeature"
              bordered :pagination="false">
              <span slot="featureName"><span style="color: red; margin-right: 5px">*</span>特征</span>
              <template v-slot:feature="text,record,index">
                <div v-if="(record.key <= 4 || isView)">{{text}}</div>
                <a-input v-if="(record.key > 4 && !isView)" :value="text"
                  @change="e=>handleChangeValue(e.target.value, index, 'feature')" />
              </template>
              <template v-slot:featureValue1="text,record,index">
                <div>
                  <div v-if="isView">{{text}}</div>
                  <a-input v-else :value="text" @change="e=>handleChangeValue(e.target.value, index, 'featureValue1')" />
                </div>
              </template>
              <template v-slot:featureValue2="text,record,index">
                <div>
                  <div v-if="isView">{{text}}</div>
                  <a-input v-else :value="text" @change="e=>handleChangeValue(e.target.value, index, 'featureValue2')" />
                </div>
              </template>
              <template v-slot:featureValue3="text,record,index">
                <div>
                  <div v-if="isView">{{text}}</div>
                  <a-input v-else :value="text" @change="e=>handleChangeValue(e.target.value, index, 'featureValue3')" />
                </div>
              </template>
              <template v-slot:featureValue4="text,record,index">
                <div>
                  <div v-if="isView">{{text}}</div>
                  <a-input v-else :value="text" @change="e=>handleChangeValue(e.target.value, index, 'featureValue4')" />
                </div>
              </template>
              <template v-slot:featureValue5="text,record,index">
                <div>
                  <div v-if="isView">{{text}}</div>
                  <a-input v-else :value="text" @change="e=>handleChangeValue(e.target.value, index, 'featureValue5')" />
                </div>
              </template>
              <template v-slot:featureValue6="text,record,index">
                <div>
                  <div v-if="isView">{{text}}</div>
                  <a-input v-else :value="text" @change="e=>handleChangeValue(e.target.value, index, 'featureValue6')" />
                </div>
              </template>
              <template v-slot:featureValue7="text,record,index">
                <div>
                  <div v-if="isView">{{text}}</div>
                  <a-input v-else :value="text" @change="e=>handleChangeValue(e.target.value, index, 'featureValue7')" />
                </div>
              </template>
              <template v-slot:featureValue8="text,record,index">
                <div>
                  <div v-if="isView">{{text}}</div>
                  <a-input v-else :value="text" @change="e=>handleChangeValue(e.target.value, index, 'featureValue8')" />
                </div>
              </template>
              <template v-slot:featureValue9="text,record,index">
                <div>
                  <div v-if="isView">{{text}}</div>
                  <a-input v-else :value="text" @change="e=>handleChangeValue(e.target.value, index, 'featureValue9')" />
                </div>
              </template>
              <template v-slot:featureValue10="text,record,index">
                <div>
                  <div v-if="isView">{{text}}</div>
                  <a-input v-else :value="text" @change="e=>handleChangeValue(e.target.value, index, 'featureValue10')" />
                </div>
              </template>
              <template v-slot:name="text,record,index">
                <div>
                  <a v-if="!!record.id" :href="baseUrl+record.id" target="_blank">{{text}}</a>
                  <span v-else>{{text}}</span>
                  <img src="../assets/delete.png" class="upload-delete" @click="deleteAttach(record, index)" v-if="!isView && text"/>
                  <!-- <a-upload v-else :file-list="record.upload_file" :data="{id:record.feature_id}"
                          :remove="handleRemoveFile" :customRequest="localUploadFile">
                    <a-icon type="upload" style="color:blue;font-size:1.5em;"/>
                </a-upload> -->
                </div>
              </template>
              <template v-slot:operation="text,record,index" v-if="!isView">
                <a-upload @change="e => handleFileChange(e, record, index)" :showUploadList="false"
                  :before-upload="beforeUpload">
                  <a>
                    附件上传
                  </a>
                </a-upload>
                 <a style="color: red; margin-left: 5px;" @click="handleDeleteRow(record)">
                    删除
                  </a>
              </template>
            </a-table>
          </div>
        </a-spin>
      </div>
  </a-modal>
</template>

<script>
import {
  getFilesData,
  addKnowledgeData,
  deleteTableFile,
  deleteImageById,
  updateKnowledgeData,
  detailKnowledgeData,
  deleteAttachFile
} from "../api/knowledge/database.js";
import { Modal, message } from 'ant-design-vue';
import { baseUrl } from '../utils/request';
import PreviewImg from './components/PreviewImg';

export default {
  name: "KnowDataDialog",
  components: {
    PreviewImg
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    dialogType: {
      type: String,
      default: "add"
    },
    knowledgeData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      baseUrl: baseUrl + '/api/ele/knowledge/database/download/file/',
      loading: false,
      originFeatureCols: [
        {
          // title: '特征',
          dataIndex: 'feature',
          width: 100,
          scopedSlots: { customRender: 'feature', title: 'featureName' },
        },
        {
          title: '特征值1',
          dataIndex: 'featureValue1',
          width: 80,
          scopedSlots: { customRender: 'featureValue1' },
        },
        {
          title: '特征值2',
          dataIndex: 'featureValue2',
          width: 80,
          scopedSlots: { customRender: 'featureValue2' },
        },
        {
          title: '特征值3',
          dataIndex: 'featureValue3',
          width: 80,
          scopedSlots: { customRender: 'featureValue3' },
        },
        {
          title: '特征值4',
          dataIndex: 'featureValue4',
          width: 80,
          scopedSlots: { customRender: 'featureValue4' },
        },
        {
          title: '特征值5',
          dataIndex: 'featureValue5',
          width: 80,
          scopedSlots: { customRender: 'featureValue5' },
        },
        {
          title: '特征值6',
          dataIndex: 'featureValue6',
          width: 80,
          scopedSlots: { customRender: 'featureValue6' },
        },
        {
          title: '特征值7',
          dataIndex: 'featureValue7',
          width: 80,
          scopedSlots: { customRender: 'featureValue7' },
        },
        {
          title: '特征值8',
          dataIndex: 'featureValue8',
          width: 80,
          scopedSlots: { customRender: 'featureValue8' },
        },
        {
          title: '特征值9',
          dataIndex: 'featureValue9',
          width: 80,
          scopedSlots: { customRender: 'featureValue9' },
        },
        {
          title: '特征值10',
          dataIndex: 'featureValue10',
          width: 90,
          scopedSlots: { customRender: 'featureValue10' },
        },
        {
          title: '附件',
          dataIndex: 'name',
          width: 120,
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 150,
          scopedSlots: { customRender: 'operation' },
        },
      ],
      featureCols: [],
      dialogWidth: 1300,
      imageFiles: [],
      featureDatas: [],
      folderName: "",
      files: [],
      fileValue: null,
      isView: true,
      knowledgeId: null,
      title: "特征管理",
      dialogSelectKeys: [],
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log('selectedRowKeys:', selectedRowKeys);
          this.dialogSelectKeys = selectedRowKeys;
        }
      },
      size: 0,
      form: {},
      rules: {
        // verified: [
        //     { required: true, message: "赋值状态不能为空", trigger: ["change", "blur"]}
        // ],
      }
    }
  },
  created() {
    this.getFilesData();
  },
  watch: {
    showModal(value) {
      let obj = null;
      this.featureCols = [...this.originFeatureCols];
      switch (this.dialogType) {
        case "add":
          this.isView = false;
          this.title = "知识数据库增加";
          break;
        case "view":
          this.isView = true;
          this.title = "知识数据库查看";
          obj = this.knowledgeData;
           this.form = {
            verified: this.knowledgeData?.verified
          };
          this.featureCols.splice(this.featureCols.length - 1, 1);
          break;
        case "edit":
          this.isView = false;
          this.title = "知识数据库编辑";
          obj = this.knowledgeData;
          this.form = {
            verified: this.knowledgeData?.verified
          };
          break;
      }
      if (this.showModal) {
        this.parseKnowledgeData(obj);
      }
    }
  },
  methods: {
    getFilesData() {
      getFilesData({}).then(res => {
        if (res.success) {
          this.files = res.data;
        }
      });
    },
    parseKnowledgeData(value) {
      const feature = {
        "featureValue1": "",
        "featureValue2": "",
        "featureValue3": "",
        "featureValue4": "",
        "featureValue5": "",
        "featureValue6": "",
        "featureValue7": "",
        "featureValue8": "",
        "featureValue9": "",
        "featureValue10": "",
      };
      if (value == null) {
        this.featureDatas = [{ key: 1, "feature": "酸性", ...feature }, { key: 2, "feature": "BET", ...feature },
        { key: 3, "feature": "XRF", ...feature }, { key: 4, "feature": "XRD", ...feature }];
        this.imageFiles = [];
        this.folderName = "";
        this.knowledgeId = null;
      } else {
        this.knowledgeId = value.id;
        this.detailKnowledgeData();
      }
    },
    // 获取知识数据库的内容
    detailKnowledgeData() {
      if (!this.knowledgeData?.id) {
        return;
      }
      this.loading = true;
      detailKnowledgeData(this.knowledgeData?.id).then(res => {
        if (res.success) {
          this.featureDatas = res.data?.attachList.map((ele, index) => {
            return {
              ...ele,
              key: index + 1
            };
          });
          this.imageFiles = res.data?.imageList.map(ele => {
            return {
              ...ele,
              src: 'data:image/jpeg;base64,' + ele.imageCode
            };
          });
          this.folderName = res.data?.name;

        }
        this.loading = false;
      });
    },
    close() {
      this.handleCancel();
    },
    handleCancel() {
      this.$parent.closeKnowledge();
      this.loading = false;
      this.featureDatas = [];
      this.imageFiles = [];
      this.form = {};

    },
    handleChange(value) {
      this.fileValue = value;
      this.knowledgeId = this.fileValue;
    },
    // async search() {
    //   if (this.fileValue) {
    //     this.imageFiles = await getImageFiles(null, null, null, this.fileValue);
    //   } else {
    //     Modal.info({
    //       title: "提示信息",
    //       content: "必须选择要查询的文件名称！",
    //       centered: true,
    //     });
    //   }
    // },
    // 删除图片
    async onDeleteImg(item) {
      const that = this;
      Modal.confirm({
        title: '提示',
        content: '是否确认删除该图片？',
        okText: '确认',
        cancelText: '取消',
        onOk() {
          if (item.id) {
            deleteImageById(item.id).then(res => {
              if(res.success) {
                that.fileDel(item);
              }
            });
          }else {
            that.fileDel(item);
          }
        }
      });
    },
    // 删除附件
    deleteAttach(item, index) {
      const newData = [...this.featureDatas];
      if(item.id) {
        deleteAttachFile(item.id).then(res => {
          if(res.success) {
              newData[index] = {
              ...newData[index],
              name: '',
              path: '',
            };
            this.featureDatas = newData;
          }
        });
      }else{
        newData[index] = {
          ...newData[index],
          name: '',
          path: '',
        };
        this.featureDatas = newData;
      }
    },
    async handleDeleteRow(record) {
      const that = this;
      Modal.confirm({
        title: '提示',
        content: '是否确认删除该特征？',
        okText: '确认',
        cancelText: '取消',
        onOk() {
          // 单个删除
          that.featureDatas = that.featureDatas.filter(ele => ele.key !== record.key);
          if(record.id) {
            that.deleteTableFile(record.id);
          }
        }
      });
      // if (this.dialogSelectKeys.length === 0) {
      //   Modal.info({
      //     title: "提示信息",
      //     content: "至少选择一条数据",
      //     centered: true,
      //   });
      //   return;
      // }
      // const ids = [];
      // this.featureDatas.map(item => {
      //   if(this.dialogSelectKeys.indexOf(item.key) > -1 && item.id) {
      //     ids.push(item.id);
      //   } 
      // });
      // this.featureDatas = this.featureDatas.filter(ele => this.dialogSelectKeys.indexOf(ele.key) === -1);
      // await this.deleteTableFile(ids);
    },
    handleAddRow() {
      let data = {
        key: Date.now(),
        feature: "",
        featureValue1: "",
        featureValue2: "",
        featureValue3: "",
        featureValue4: "",
        featureValue5: "",
        featureValue6: "",
        featureValue7: "",
        featureValue8: "",
        featureValue9: "",
        featureValue10: "",
      };
      this.featureDatas.push(data);
    },
    beforeUpload(file) {
      const isRepeatName = this.featureDatas.find(ele => ele.name === file.name);
      if (isRepeatName) {
        message.error('已存在同名文件!');
      }
      const isLt2M = file.size / 1024 / 1024 < 20;
      if (!isLt2M) {
        message.error('文件不能大于20M!');
      }
      return !isRepeatName && isLt2M;
    },
    handleFileChange(e, record, index) {
      record = {
        ...record,
        path: e.file,
        name: e.file.name,
        isEdit: true,
      };
      this.featureDatas[index] = { ...record };
      this.$set(this.featureDatas, index, record);
    },
    // 删除附件文件
    deleteTableFile(id) {
      if (!id) {
        return;
      }
      deleteTableFile(id).then(res => {
        if (res.success) {
          message.success("操作成功");
        }
      });
    },
    handleChangeValue(value, index, name) {
      this.featureDatas[index][name] = value;
    },
    fileClick(){
        // 上传按钮
        document.getElementById('upload_file').click()
    },
    fileChange(el){
        if (!el.target.files[0].size) return;
        this.fileList(el.target.files);
        el.target.value = ''
    },
    fileList(files){
        for (let i = 0; i < files.length; i++) {
            this.fileAdd(files[i]);
        }
    },
    fileAdd(file){
        const that = this;
        this.size = this.size + file.size;//总大小
        let reader = new FileReader();
        reader.vue = this;
        reader.readAsDataURL(file);
        reader.onload = function () {
            file.src = this.result;
            that.imageFiles.push(file);
        }
    },
    fileDel(file) {
      if(file.size) {
        this.size = this.size - file.size;
      }
      this.imageFiles.splice(file.index, 1);
    },
    bytesToSize(bytes){
        if (bytes === 0) return '0 B';
        let k = 1000, // or 1024
            sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i];
    },
    saveKnowledge() {
      // if (this.knowledgeId == null) {
      //   Modal.info({
      //     title: "提示信息",
      //     content: "必须选择要查询的文件名称！",
      //     centered: true,
      //   });
      //   return;
      // }
      if(this.imageFiles.length === 0) {
        message.error("请上传图片");
        return;
      }
      const formData = new FormData();
      if(this.knowledgeData?.id) {
        formData.append("id", this.knowledgeData?.id);
        formData.append("name", this.folderName);
      }
      formData.append("verified", this.form.verified);
      for (let i = 0; i < this.imageFiles.length; i++) {
        if (this.imageFiles[i] && !this.imageFiles[i].id) {
          formData.append("images", this.imageFiles[i]);
        }
      }
      const features = [];
      for (let i = 0; i < this.featureDatas.length; i++) {
        // if (!this.featureDatas[i]?.name) {
        //   message.error("第" + (i + 1) + '行未上传附件！');
        //   return false;
        // }
        if (!this.featureDatas[i]?.feature) {
          message.error("第" + (i + 1) + '行未填写特征！');
          return false;
        }
        if (this.featureDatas[i].path && this.featureDatas[i].isEdit) {
          formData.append("attachs", this.featureDatas[i].path?.originFileObj);
        }
        const obj = { ...this.featureDatas[i] };
        delete obj.path;
        delete obj.key;
        delete obj.isEdit;
        features.push(obj);
      }
      formData.append(`attachListStr`, JSON.stringify(features));
      this.loading = true;
      if (this.knowledgeData?.id) { // 修改
        updateKnowledgeData(formData).then(res => {
          if (res.success) {
            message.success('操作成功');
            this.close();
            this.$emit('onFresh');
          }
          this.loading = false;
        });
      } else {
        addKnowledgeData(formData).then(res => {
          if (res.success) {
            message.success('操作成功');
            this.close();
            this.$emit('onFresh');
          }
          this.loading = false;
        });
      }
    },
    handleRemoveFile(file) {
      console.log("remove is ", file);
      this.featureDatas.forEach(function (item) {
        if (item.feature_id == file.uid) {
          item.feature_file = "";
          item.upload_file = [];
          return;
        }
      });
      this.$forceUpdate();
    },
    localUploadFile(e) {
      console.log("localUploadFile is ", e);
      this.featureDatas.forEach(function (item) {
        if (item.feature_id == e.data.id) {
          item.feature_file = "";
          item.upload_file = [];
          e.file.uid = item.feature_id;
          item.upload_file.push(e.file);
          return;
        }
      });
      this.$forceUpdate();
    },

  }
}
</script>

<style scoped>
::v-deep .ant-upload {
  /* float:right; */
  /* padding-top: 15px; */
}

/deep/ .ant-upload-list-item-info {
  margin: 0px;
  width: 280px;
  text-align: left;
}

/*/deep/ .ant-upload-list-item-card-actions{*/
/*  font-size:1.5em;*/
/*}*/
/deep/ .ant-upload-list {
  float: left;
}

/deep/ .ant-upload-list div {
  margin: 0px;
}

.dialogTitle {
  background: lightgray;
  font-size: 12px;
}

.main {
  background: white;
  margin: 10px 20px;
}

.subtitle {
  font-weight: bolder;
  font-size: 24px;
  text-align: center;
  color: black;
}

.head {
  height: 61px;
  background: url("../assets/banner.png") no-repeat;
  background-size: 224px 61px
}

/*/deep/ .ant-modal-content{*/
/*  padding: 0px;*/
/*  margin:0px;*/
/*  border:1px solid blue;*/
/*}*/
/deep/ .ant-modal-header {
  /* background: lightgray; */
  height: 31px;
  padding-top: 3px;
  padding-left: 20px;
  border-bottom: 1px solid #EEEEEE;
}

/deep/ .ant-modal-body {
  padding: 0px;
}

/deep/ .ant-modal-body input {
  /* border: none; */
  border-radius: 0px;
  /* border-bottom: 1px solid black; */
}

/deep/ .ant-modal-body input:focus {
  border: none;
  border-radius: 0px;
  /* border-bottom: 1px solid black; */
}

/deep/ .ant-row {
  margin: 0px;
  padding: 0px;
}

.know-add-btn {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
}

.know-add-btn button:not(:last-child) {
  margin-right: 5px;
}
.dialog-table{
  overflow: auto;
}
.dialog-table .ant-upload {
  display: flex;
  justify-content: center;
  width: 100%;
}

.upload_warp_img_div_del {
        position: absolute;
        width: 16px;
        right: 8px;
        left: 50%;
    }
.upload_warp_img_div_top {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 30px;
        background-color: rgba(0, 0, 0, 0.4);
        line-height: 30px;
        text-align: center;
        color: #fff;
        font-size: 12px;
        text-indent: 4px;
    }
    .upload_warp_img_div_top .ant-btn{
        color: white;
        border: none;
    }
.upload_warp_img_div_text {
    white-space: nowrap;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.upload_warp_img_div img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
}

.upload_warp_img_div {
    position: relative;
    height: 100px;
    width: 120px;
    border: 1px solid #ccc;
    margin: 0px 5px 5px 0px;
    float: left;
    line-height: 100px;
    display: table-cell;
    text-align: center;
    background-color: #eee;
    cursor: pointer;
}

.upload_warp_img {
    /* border-top: 1px solid #D2D2D2; */
    padding: 5px 0 0 5px;
    overflow: hidden
}

.upload_warp_text {
    text-align: left;
    margin-bottom: 5px;
    padding-top: 5px;
    text-indent: 14px;
    border-top: 1px solid #ccc;
    font-size: 14px;
}

.upload_warp_right {
    float: left;
    width: 57%;
    margin-left: 2%;
    height: 100%;
    border: 1px dashed #999;
    border-radius: 4px;
    line-height: 130px;
    color: #999;
}
.upload_warp_left button {
    margin: 1px 5px 0px 0px;
    cursor:pointer;
}
.upload_warp_left {
    float: left;
}
.upload_warp_left .btn-success{
    background: #4F60BA;
    color:#fff;
}
.upload_warp {
    margin: 5px;
    clear: both;
}
.upload {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    background-color: #fff;
    width: 100%;
    box-shadow: 0px 1px 0px #ccc;
    border-radius: 4px;
}
.img {
    width: 100%;
    text-align: center;
}

.upload-delete {
  width: 17px;
  height: 17px;
  margin-left: 5px;
  cursor: pointer;
}
.imgContainer{
    box-shadow: 0px 1px 12px 0px rgba(61,74,148,0.5);
    border-radius: 4px;
  }
</style>