import request from "../../utils/request";

// 获取查询条件框需要的文件列表数据，
export function getFilesData(query) {
  return request({
    url: '/api/ele/knowledge/database/nameList',
    method: 'get',
    params: query
  });
}

// 知识数据库列表
export function getKnowledgeDataList(query) {
  return request({
    url: '/api/ele/knowledge/database/list',
    method: 'get',
    params: query
  });
}

// 删除图片
export function deleteImageById(id) {
    return request({
      url: '/api/ele/knowledge/database/delete/image/' + id,
      method: 'delete',
    });
  }

/**
 * 
 * @returns 删除特征行
 */
export function deleteTableFile(id) {
  return request({
    url: '/api/ele/knowledge/database/delete/file/' + id,
    method: 'delete',
  });
}

/**
 * 
 * @returns 删除附件
 */
 export function deleteAttachFile(id) {
  return request({
    url: '/api/ele/knowledge/database/delete/attachfile/' + id,
    method: 'delete',
  });
}

// 删除知识数据库
export function deleteKnowledgeData(id) {
  return request({
    url: '/api/ele/knowledge/database/' + id,
    method: 'delete',
  });
}

// 增加知识数据库
export function addKnowledgeData(data) {
    return request({
        url: '/api/ele/knowledge/database/add',
        method: 'post',
        data: data
    });
}

// 修改知识数据库
export function updateKnowledgeData(data) {
    return request({
        url: '/api/ele/knowledge/database/update',
        method: 'post',
        data: data
    });
}

// 查看知识数据库
export function detailKnowledgeData(id) {
    return request({
        url: '/api/ele/knowledge/database/' + id,
        method: 'get',
    });
}